import React from 'react';
import {COLORS} from '@fupa/fupa-uikit';
import {
  StreamIcon,
  Category,
  Container,
  Header,
  Line,
  Timestamp,
  CategoryName,
  MessageHeading,
} from 'app/styles/stream';
import {formatShortDate} from 'app/helpers/dateHelpers';
import {Group, InsertComment, Person} from '@mui/icons-material';
import {Coach, Emblem} from 'app/components/icons';

const MarketplaceHeader = ({element}) => {
  const Time = <Timestamp>{formatShortDate(element.timestamp)}</Timestamp>;

  const iconStyle = {color: COLORS.white, fontSize: 16};
  const icon = {
    searchingopponent: <Group style={iconStyle} />,
    searchingclub: <Emblem style={iconStyle} />,
    searchingcoach: <Coach style={iconStyle} />,
    searchingplayer: <Group style={iconStyle} />,
    clubsearching: <Emblem style={iconStyle} />,
    coachsearching: <Coach style={iconStyle} />,
    playersearching: <Person style={iconStyle} />,
    other: <InsertComment style={iconStyle} />,
  };

  const getSubHeading = (anonymous, category) => {
    if (anonymous) {
      return 'Anonym';
    } else if (category === 'other') {
      return element.club
        ? element.club.name
        : element.author
        ? element.author.firstName + ' ' + element.author.lastName
        : 'Unbekannt';
    } else if (category === 'coachsearching' || category === 'playersearching' || category === 'searchingclub') {
      return element.author ? element.author.firstName + ' ' + element.author.lastName : 'Unbekannt';
    } else {
      return element.club ? element.club.name : 'Unbekannt';
    }
  };

  const subHeading = getSubHeading(element.anonymous, element.category.slug);

  return (
    <Header variant='caption3'>
      <Container>
        <StreamIcon color={COLORS.marketBlue}>{icon[element.category.slug]}</StreamIcon>
        <Category>
          <CategoryName>{element.category.name}</CategoryName>
          <MessageHeading>{subHeading}</MessageHeading>
        </Category>
        {Time}
      </Container>
      <Line />
    </Header>
  );
};

export default MarketplaceHeader;
