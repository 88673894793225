import {useEffect} from 'react';

/**
 * @deprecated use InfiniteScrollTrigger instead
 */
function useInfiniteScrollComponentState(loadData, dataLength) {
  const threshold = 200;

  const onScroll = () => {
    // improve system robustness by checking dataLength to prevent duplicate api-calls
    if (dataLength && window.scrollY + window.innerHeight >= document.body.clientHeight - threshold) {
      loadData(true);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  });
}

export {useInfiniteScrollComponentState};
